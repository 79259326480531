import React, { useState, useEffect } from 'react';
import { getAuth, signInWithPopup, GithubAuthProvider, setPersistence, browserLocalPersistence, updateProfile } from "firebase/auth";
import { Star, Flame, Cog, DollarSign, Users, ArrowLeft, Plus, Menu, User, CheckCheck, CircleX, CheckIcon, LoaderIcon, Globe2, ArrowRightIcon, CheckCircleIcon } from "lucide-react";
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, deleteDoc, doc, updateDoc, arrayRemove, onSnapshot } from 'firebase/firestore';
import Teams from "./teams"
import Billing from "./billing"
import { FaServer, FaClock, FaPlus, FaTrash } from 'react-icons/fa';
import { FaGlobe, FaCog, FaGithub, FaCopy } from 'react-icons/fa';
import AccountSettings from "./accountSettings"
import { Globe, Server, Package } from 'lucide-react';
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import GithubConnect from './githubConnect';

const generateDeploymentId = () => {
  const now = new Date();
  return `${now.getFullYear()}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getDate()).padStart(2, '0')}_${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
};


const firebaseConfig = {
  apiKey: "AIzaSyBPxueucxQtV0Zbxe8BtlT6GoSR753TNd4",
  authDomain: "gammafy-638dc.firebaseapp.com",
  projectId: "gammafy-638dc",
  storageBucket: "gammafy-638dc.appspot.com",
  messagingSenderId: "849864306670",
  appId: "1:849864306670:web:506911cfb604f41628a8ff",
  measurementId: "G-74ZH9LRDP3"
};
  
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const provider = new GithubAuthProvider();
provider.addScope('repo');

const Dashboard = ({ user, data, userPlan }) => {
  const [repositories, setRepositories] = useState([]);
  const [showList, setShowList] = useState(false);
  const [indRepo, setIndRepo] = useState(null);
  const [main, setMain] = useState(true);
  const [details, setDetails] = useState()
  const [forward, setForward] = useState(true);
  const [deploying, setDeploying] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState('');
  const [showTypes, setShowTypes] = useState(true)

  const [number, setNumber] = useState(0);

  useEffect(() => {
    setNumber(data.length)
  }, [data.length])

  const signInWithGitHub = () => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        return signInWithPopup(auth, provider);
      })
      .then((result) => {
        const credential = GithubAuthProvider.credentialFromResult(result);
        const newToken = credential.accessToken;
        setToken(newToken);
        setWebServiceFormData(prevData => ({
          ...prevData,
          token: newToken
        }));
        fetchRepositories(newToken);
        setShowList(true);
        setShowTypes(false)
      })
      .catch((error) => {
        console.error("Error during sign-in:", error.code, error.message);
      });
  };

  const [userLogs, setUserLogs] = useState([]);

  useEffect(() => {
    if (!user) {setUserLogs([]); return;}

    const q = query( collection(db, 'logs'),where('userId', '==', user.uid) );

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const logs = [];
      querySnapshot.forEach((doc) => {
        logs.push({ id: doc.id, ...doc.data() });
      });
      setUserLogs(logs);
    }, (error) => {
      console.error("Error fetching logs:", error);
    });

    return () => unsubscribe();
  }, [user]);

  const fetchRepositories = async (accessToken, searchQuery = '', pageNum = 1) => {
    setLoading(true);
    try {
      const response = await fetch(`https://api.github.com/user/repos?page=${pageNum}&per_page=99&q=${searchQuery}`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      if (pageNum === 1) {
        setRepositories(data);
      } else {
        setRepositories(prev => [...prev, ...data]);
      }

      setPage(pageNum);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching repositories:", error);
      setLoading(false);
    }
  };

  const plans = [
    { plan: "Free", price: "0", cpu: "0.5", ram: "0.5" },
    {plan: "Beta", price: "6", cpu: "1", ram: "1"},
    { plan: "Omega", price: "12", cpu: "1", ram: "2" },
    { plan: "Delta", price: "18", cpu: "2", ram: "2" },
  ]

  const [webServiceFormData, setWebServiceFormData] = useState({
    token: token,
    userIdOne: user?.uid,
    deploymentId: '',
    webAppName: '',
    server_type: "",
    plan: "Beta",
    repoUrl: '',
    branch: 'main',
    runtime: 'Django',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
    envVars: []
  });

  const [nameAvailability, setNameAvailability] = useState(null);
  const appNamesRef = collection(db, "appnames");

  const checkNameAvailability = async (name) => {
    if (!name) {
      return 'Please enter a name first';
    }

    try {
      const q = query(appNamesRef, where('name', '==', name));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        return 'Name available!';
      } else {
        return 'Name already taken!';
      }
    } catch (error) {
      console.error('Error checking name availability:', error);
      return 'Error checking availability';
    }
  };

  const handleCheckNameAvailability = async () => {
    const result = await checkNameAvailability(webServiceFormData.webAppName);
    setNameAvailability(result);
  };

  const goBack = () => {
    setShowList(true);
    setIndRepo(null);
    setMain(true);
    setForward(true);
    setNameAvailability(null);
    setWebServiceFormData({
      token: token,
      webAppName: '',
      repoUrl: '',
      branch: 'main',
      runtime: 'Django',
      startupCommand: '',
      portNumber: "",
      location: 'ams',
      nodejsVersion: '14.x',
      envVars: []
    });
  };
  const [activeSection, setActiveSection] = useState('main');

  const handleReload = (e) => {
    e.preventDefault();
    setActiveSection('main')
    setForward(true)
    setShowTypes(true)
    setShowList(false)
    setIndRepo(null)
  };

  const menuItems = [
    { icon: Flame, text: "Deployments", onClick: (e) => handleReload(e) },
    { icon: DollarSign, text: "Billing", onClick: () => setActiveSection('billing') },
    { icon: Users, text: "Teams", onClick: () => setActiveSection('teams') },
    { icon: User, text: "Account", onClick: () => setActiveSection('user') }
  ];

  const stats = [
    { title: "Deployments", value: `${data?.length}` },
    { title: "Team members", value: "1" }
  ];

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const [selectedType, setSelectedType] = useState(null);
  const [cronJobs, setCronJobs] = useState([{ endpoint: '', schedule: '' }]);

  const deploymentTypes = [
    { id: 'webservice', name: 'New Web Service', icon: FaServer },
    { id: 'cronjob', name: 'New Cron Job', icon: FaClock },
  ];

  const [deploymentType, setDeploymentType] = useState('cron');

  const handleDeploymentTypeSelect = (type) => {
    if (!user) {
      signInWithGitHub();
      return;
    }

    signInWithGitHub();
    setSelectedType(type);
  };


  const [cronFormData, setCronFormData] = useState({
    webAppName: '',
    repoUrl: '',
    branch: '',
    runtime: 'Django',
    startupCommand: '',
    portNumber: "",
    location: 'ams',
    nodejsVersion: '14.x',
  });

  const handleWebServiceFormChange = (e) => {
    setWebServiceFormData({ ...webServiceFormData, [e.target.name]: e.target.value });
  };

  const deploymentsRef = collection(db, "deployments");
  const [getResponse, setGetResponse] = useState(false);
  const [successful, setSuccessful] = useState(false);

  const [showTooltip, setShowTooltip] = useState(false);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(`https://${selectedDeployment?.data?.endpoint}`);
    setShowTooltip(true);
  };

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowTooltip(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltip]);
  

  const userId = user?.uid;
  const [subscription, setSubscription] = useState(null);
  const [allowed, setAllowed] = useState()
  const [subscriptionId, setSubscriptionId] = useState()
  const [instanciaId, setInstanciaId] = useState();
  const [subsId, setSubsId] = useState()
  const [permitir, setPermitir] = useState(false);

  const [hasProfile, setHasProfile] = useState(false)

  useEffect(() => {
    if (!userId) return;
    const fetchSubscription = async () => {
      try {
        const userRef = doc(db, 'payments_profile', userId);
        const docSnap = await getDoc(userRef);
        if (docSnap.exists()) {
          const data = docSnap.data();
          setSubscription(data?.subscription);
          setAllowed(data?.response?.plan)
          setHasProfile(data.active)
          setSubsId(data?.subscription.attributes.subscription_id)
          setInstanciaId(data?.instanceId)
          setSubscriptionId(data?.subscription?.attributes?.subscription_id)
        } else {
          setHasProfile(false)
          ;}
      } catch (error) {console.error('Error fetching subscription:', error);}
    };

    fetchSubscription();
  }, [userId]);

  const handleCronFormChange = (e) => {
    setCronFormData({ ...cronFormData, [e.target.name]: e.target.value });
  };

  const handleCronJobChange = (index, field, value) => {
    const updatedCronJobs = [...cronJobs];
    updatedCronJobs[index][field] = value;
    setCronJobs(updatedCronJobs);
  };

  const addCronJob = () => {
    setCronJobs([...cronJobs, { endpoint: '', schedule: '' }]);
  };

  const [billingToken, setBillingToken] = useState('');


  const removeCronJob = (index) => {
    const updatedCronJobs = cronJobs.filter((_, i) => i !== index);
    setCronJobs(updatedCronJobs);
  };

  const [cronSubmit, setCronSubmit] = useState()
  const [isDeleting, setIsDeleting] = useState(false);

  const handleSubmitCron = async (e) => {
    e.preventDefault();
    setDeploying(true);
    try {
      const response = await axios.post('https://mia.my-coolcloud.com/deploy-cron', {
        ...cronFormData,
        cronJobs,
      });
      setSuccessful(true);
      setCronSubmit(response.data);
    } catch (error) {
      setSuccessful(false);
    } finally {
      setDeploying(false);
    }
  };

  const [showTooltipX, setShowTooltipX] = useState(false);

  const handleCopyX = (text) => {
    navigator.clipboard.writeText(text);
    setShowTooltipX(true);
  };

  useEffect(() => {
    if (showTooltipX) {
      const timer = setTimeout(() => setShowTooltipX(false), 2000);
      return () => clearTimeout(timer);
    }
  }, [showTooltipX]);

  const handleSubmitWebService = async (e) => {
    e.preventDefault();
    setDeploying(true);
    setGetResponse(true);

    const deploymentId = generateDeploymentId();
    console.log("Generated Deployment ID:", deploymentId); 

    const envVarsString = JSON.stringify(webServiceFormData.envVars);

    try {
      console.log({ ...webServiceFormData, envVars: envVarsString, deploymentId })
      const response = await axios.post( 
        'http://localhost:5000/deploy',
        { ...webServiceFormData, envVars: envVarsString, deploymentId}
      );
      setWebServiceFormData(prevData => ({
        ...prevData,
        deploymentId
      }));
      console.log("Form Data after setting deploymentId:", { ...webServiceFormData, deploymentId });
      setDetails(response.data);
      setGetResponse(false);
      setSuccessful(true);

    } catch (error) {
      setGetResponse(false);
      console.error("Deployment Error:", error);
    } finally {
      setGetResponse(false);
      setDeploying(false);
    }
  };

  const handleSelect = (type) => {
    setSelectedType(type);
    setDeploymentType(type);
    handleDeploymentTypeSelect(type);
  };

  const repoSelected = (repo) => {
    setShowList(false);
    setForward(false);
    setIndRepo(repo);
    const formData = {
      webAppName: repo.name,
      repoUrl: repo.html_url
    };
    setCronFormData(prev => ({ ...prev, ...formData }));
    setWebServiceFormData(prev => ({ ...prev, ...formData }));
  };

  const deleteSubscription = async () => {
    try {
      const deleteInstanceResponse = await fetch(`https://mia.my-coolcloud.com/delete/${instanciaId}`, { method: 'DELETE' });
      if (!deleteInstanceResponse.ok) {
        throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
      }
      window.location.reload()
    }
    catch (err) {
      console.log(err)
    }
  }

  const [selectedDeployment, setSelectedDeployment] = useState(null);

  const navigate = useNavigate();

  const handleDeploymentClick = (deployment) => {
    navigate(`/deployment/${deployment.id}`);
  };

  const handleEnvVarChange = (index, field, value) => {
    setWebServiceFormData(prevData => {
      const newEnvVars = [...prevData.envVars];
      newEnvVars[index] = { ...newEnvVars[index], [field]: value };
      return { ...prevData, envVars: newEnvVars };
    });
  };

  const setPlan = (selectedPlan) => {
    setWebServiceFormData(prevFormData => ({
      ...prevFormData,
      plan: selectedPlan.plan,
      server_type: selectedPlan.cpu + " CPU / " + selectedPlan.ram + " RAM"
    }));
  }

  const addEnvVar = () => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: [...prevData.envVars, { key: '', value: '' }]
    }));
  };

  const removeEnvVar = (index) => {
    setWebServiceFormData(prevData => ({
      ...prevData,
      envVars: prevData.envVars.filter((_, i) => i !== index)
    }));
  };

  const [isAvailabilityButtonDisabled, setIsAvailabilityButtonDisabled] = useState()

  return (
    <div className="flex flex-col h-screen bg-gray-100 md:flex-row">
      <header className="bg-white shadow-sm md:hidden">
        <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            <img className='w-24 h-auto' src='https://maresone.s3.us-east-1.amazonaws.com/fino_logo.png' alt='' />
          </Link>
          <button onClick={toggleMobileMenu} className="text-gray-500 hover:text-gray-600">
            <Menu size={24} />
          </button>
        </div>
      </header>
      <div className={`${mobileMenuOpen ? 'block' : 'hidden'} md:block md:w-64 bg-white shadow-lg`}>
        <div className="flex items-center justify-center h-16 border-b">
          <Link to="/" className="text-xl font-bold text-indigo-600 flex items-center">
            <img className='w-20 h-auto' src='https://maresone.s3.us-east-1.amazonaws.com/fino_logo.png' alt='' />
          </Link>
        </div>
        <nav className="mt-6">
          {menuItems.map((item, index) => (
            <a
              key={index}
              onClick={item.onClick}
              className="flex items-center px-6 py-3 text-gray-600 hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer"
            >
              <item.icon className="mr-3" size={20} />
              {item.text}
            </a>
          ))}
        </nav>
      </div>

      <div className="flex-1 overflow-y-auto">
        <header className="bg-white shadow-sm hidden md:block">
          <div className="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8 flex justify-between items-center">
            <h1 className="text-2xl font-semibold text-gray-900"></h1>
            <div className="bg-indigo-600 text-white w-10 h-10 flex items-center justify-center rounded-full font-bold">
              <img className='rounded-full' src={user?.photoURL} alt='' />
            </div>
          </div>
        </header>
        <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
          {activeSection === 'main' && (
            <>
              <div className="pb-6 flex items-center">
                <p className='text-xl font-semibold'>{user?.displayName}</p>
                <button className='px-3 pt-1 font-semibold rounded-lg text-sm bg-gray-200 ml-2'>{userPlan}</button>
              </div>
              {forward && (
                <>
                  <div className="mb-8">
                    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
                      {stats.map((item, index) => (
                        <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                          <div className="px-4 py-5 sm:p-6">
                            <dt className="text-sm font-medium text-gray-500 truncate">{item.title}</dt>
                            <dd className="mt-1 text-2xl md:text-3xl font-semibold text-gray-900">{item.value}</dd>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  {showTypes && (
                    <>
                      <div className="grid grid-cols-2 gap-4 mb-4">
                        {deploymentTypes.map((type) => (
                          <button
                            key={type.id}
                            onClick={() => handleSelect(type.id)}
                            className={`flex items-center justify-center p-4 border rounded-lg ${selectedType === type.id
                                ? 'bg-indigo-100 border-indigo-500'
                                : 'border-gray-300 hover:border-indigo-500'
                              }`}
                          >
                            <type.icon className="mr-2 text-indigo-600" size={20} />
                            <span>{type.name}</span>
                          </button>
                        ))}
                      </div>

                      <div className="mt-8">
                        {data?.length > 0 &&
                          <h2 className="text-2xl font-semibold text-gray-900 mb-6">My Deployments</h2>
                        }
                        <div className="grid grid-cols-1 gap-6">
                          {data.map((element, index) => (
                            <div key={index} className="bg-white overflow-hidden shadow rounded-lg">
                              <div className="px-4 py-5 sm:p-6">
                                <div onClick={() => handleDeploymentClick(element)} className='flex items-center cursor-pointer'>
                                  <h3 className="text-lg font-medium underline text-gray-900 truncate mb-2">
                                    {element?.data?.data?.webAppName}
                                  </h3>
                <ArrowRightIcon className='text-gray-900 w-6 h-auto pl-2' />
                              </div>
                                <dl className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Globe className="mr-2 h-5 w-5 text-indigo-500" />
                                      Location
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.data?.location}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Server className="mr-2 h-5 w-5 text-indigo-500" />
                                      Runtime
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data.data?.runtime}
                                    </dd>
                                  </div>
                                  <div className="sm:col-span-1 flex items-center">
                                    <dt className="text-sm font-medium text-gray-500 flex items-center mb-1">
                                      <Package className="mr-2 h-5 w-5 text-indigo-500" />
                                      Plan
                                    </dt>
                                    <dd className="text-sm ml-2 text-gray-900 bg-indigo-50 rounded-md px-2 py-1 inline-block">
                                      {element?.data?.data?.plan}
                                    </dd>
                                  </div>
                                </dl>
                                <div className="mt-6 space-y-2">
                                  <div className="text-sm underline text-indigo-600 hover:text-indigo-500 flex items-center">
                                    <Globe className="mr-2 h-4 w-4" />
                                    <a href={`https://${element?.data?.endpoint}`} target='_blank' className='cursor-pointer'>
                                      https://{element?.data?.endpoint}
</a>
                                  </div>
                                  <a href={element?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-sm text-gray-600 hover:text-gray-500 flex items-center">
                                    <FaGithub className="mr-2 h-4 w-4" />
                                    {element?.data?.data?.repoUrl}
                                  </a>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {showList && <GithubConnect searchTerm={searchTerm} setSearchTerm={setSearchTerm} setForward={setForward} repoSelected={repoSelected} repos={repositories} />}
              {indRepo && (
                <div className="mt-3">
                  <button onClick={goBack} className="flex items-center text-indigo-600 hover:text-indigo-800 mb-6">
                    <ArrowLeft className="mr-2" size={20} />
                    <span className="font-semibold">Go back</span>
                  </button>
                  <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  {selectedType === "webservice" && (
                    <>
                        <div className="px-4 py-5 sm:px-6">
                          <h3 className="text-lg leading-6 mb-2 font-medium text-gray-900">Deploy your project</h3>
                          <p className="mb-3 mt-8 max-w-2xl text-sm text-gray-500">
                            Configuration for {indRepo.name}
                          </p>
                          <div>
                            {details && (
                              <div className="flex items-center">
                                <a
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="text-indigo-600 flex items-center"
                                >
                                  <p className='text-black no-underline mr-2'>Your endpoint: </p>  
                                  <a
                                    href={`https://${details?.endpoint}`}
                                    target='_blank'
                                    className='cursor-pointer hover:underline'
                                  >https://{details?.endpoint}</a> 
                                  <CheckCircleIcon className='text-green-500 w-4 h-auto ml-2' />
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <form onSubmit={handleSubmitWebService}>
                            <dl className="sm:divide-y sm:divide-gray-200">
                              {[
                                {
                                  label: "Deployment Name",
                                  description: "Enter a unique name for your deployment. Check if it is available.",
                                  name: "webAppName",
                                  type: "text",
                                  validate: (value) => {
                                    const sanitized = value.replace(/[^a-z-]/g, '');
                                    return sanitized.replace(/^[0-9]/, '');
                                  },
                                  transform: (value) => {
                                    return value.replace(/\s+/g, '-');
                                  }
                                },
                                { label: "Repository URL", description: "This is the link from your GitHub repository", name: "repoUrl", type: "text", disabled: true },
                                { label: "Branch", description: "Specify the branch to deploy", name: "branch", type: "text" },
                                {
                                  label: "Runtime",
                                  description: "Select the runtime environment for your application",
                                  name: "runtime",
                                  type: "select",
                                  options: ["Django", "NodeJS"]
                                },
                                {
                                  label: "Location",
                                  description: "Choose the server location for your deployment",
                                  name: "location",
                                  type: "select",
                                  labels: [
                                    "Amsterdam (Netherlands)",
                                    "Atlanta (Georgia, USA)",
                                    "New Jersey (New Jersey, USA)",
                                    "Paris (France)",
                                    "Dallas (Texas, USA)",
                                    "Frankfurt (Germany)",
                                    "Hong Kong",
                                    "Los Angeles (California, USA)",
                                    "London (United Kingdom)",
                                    "Manchester (United Kingdom)",
                                    "Miami (Florida, USA)",
                                    "Mexico City (Mexico)"
                                  ],
                                  options: [
                                    "ams",
                                    "atl",
                                    "ewr",
                                    "cdg",
                                    "dfw",
                                    "fra",
                                    "hkg",
                                    "lax",
                                    "lhr",
                                    "man",
                                    "mia",
                                    "mex"
                                  ]
                                },
                                ...(webServiceFormData.runtime === "NodeJS" ? [
                                  {
                                    label: "Startup Command",
                                    description: "Command to start your application. Typically, it is node index.js",
                                    name: "startupCommand",
                                    type: "text"
                                  },
                                  {
                                    label: "Port Number",
                                    description: "The port number on which your server is running (Default is 5000)",
                                    name: "portNumber",
                                    type: "text"
                                  },
                                  {
                                    label: "NodeJS version",
                                    description: "Select the version of NodeJS for your application. You can find it by running node -v",
                                    name: "nodejsVersion",
                                    type: "select",
                                    options: ["14.x", "16.x", "18.x", "20.x", "22.x"]
                                  }
                                ] : []),
                              ].map((field, index) => (
                                <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                  <dt className="text-lg font-medium text-gray-800">
                                    {field.label}
                                    {field.description && (
                                      <p className="mt-1 text-sm font-light text-gray-700">{field.description}</p>
                                    )}
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                    <div className="flex items-center">
                                      {field.type === "select" ? (
                                        <select
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={handleWebServiceFormChange}
                                          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        >
                                          {field.name === "location"
                                            ? field.labels.map((label, optionIndex) => (
                                              <option key={optionIndex} value={field.options[optionIndex]}>
                                                {label}
                                              </option>
                                            ))
                                            : field.options.map((option, optionIndex) => (
                                              <option key={optionIndex} value={option}>
                                                {option}
                                              </option>
                                            ))}
                                        </select>
                                      ) : (
                                        <input
                                          type={field.type}
                                          name={field.name}
                                          value={webServiceFormData[field.name]}
                                          onChange={(e) => {
                                            let value = e.target.value;
                                            if (field.validate) {
                                              value = field.validate(value);
                                            }
                                            if (field.transform) {
                                              value = field.transform(value);
                                            }
                                            handleWebServiceFormChange({
                                              target: { name: field.name, value }
                                            });

                                            if (field.name === 'webAppName') {
                                              const isValid = /^[a-z-]*$/.test(value);
                                              e.target.style.borderColor = isValid ? 'initial' : 'red';
                                              setNameAvailability(null);
                                              setIsAvailabilityButtonDisabled(!isValid);
                                            }
                                          }}
                                          className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                          required
                                          disabled={field.disabled}
                                        />
                                      )}
                                      {field.name === 'webAppName' && (
                                        <button
                                          type="button"
                                          onClick={handleCheckNameAvailability}
                                          className="ml-2 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                          disabled={isAvailabilityButtonDisabled}
                                        >
                                          Availability
                                        </button>
                                      )}
                                    </div>
                                    {field.name === 'webAppName' && nameAvailability && (
                                      <div className={`mt-2 font-medium text-sm ${nameAvailability === 'Name available!' ? 'text-green-500' : 'text-red-600'}`}>
                                        {nameAvailability}
                                      </div>
                                    )}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                            <div className="mt-6 sm:px-6">
                              <h3 className="text-lg font-medium text-gray-900">Environment Variables</h3>
                              {webServiceFormData.envVars.map((envVar, index) => (
                                <div key={index} className="mt-2 flex items-center space-x-2">
                                  <input
                                    type="text"
                                    placeholder="Key"
                                    value={envVar.key}
                                    onChange={(e) => handleEnvVarChange(index, 'key', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <input
                                    type="text"
                                    placeholder="Value"
                                    value={envVar.value}
                                    onChange={(e) => handleEnvVarChange(index, 'value', e.target.value)}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                  <button
                                    type="button"
                                    onClick={() => removeEnvVar(index)}
                                    className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                  >
                                    Remove
                                  </button>
                                </div>
                              ))}
                              <button
                                type="button"
                                onClick={addEnvVar}
                                className="mt-4 inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-green-500"
                              >
                                Add Environment Variable
                              </button>
                            </div>
                            <div className='grid grid-cols-1 sm:grid-cols-3'>
                              {plans?.map((element, index) => (
                                <div
                                  key={index}
                                  className={`rounded py-3 px-4 mx-10 my-4 cursor-pointer border border-indigo-600 hover:border-2 ${webServiceFormData.plan === element.plan ? 'bg-indigo-100' : ''
                                    }`}
                                  onClick={() => setPlan(element)}
                                >
                                  <div className='flex justify-between items-center'>
                                    <p className='font-semibold text-gray-800 text-lg'>{element?.plan}</p>
                                    <p className='text-gray-600 font-medium'>{element?.price} USD / month</p>
                                  </div>
                                  <div className='flex justify-between items-center'>
                                    <p className='text-gray-600 font-medium'>{element?.cpu} CPU</p>
                                    <p className='text-gray-600 font-medium'>{element?.ram} RAM</p>
                                  </div>
                                </div>
                              ))}
</div>
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                              <button
                                type="submit"
                                className={`inline-flex justify-center py-2 px-4 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 ${deploying || nameAvailability !== 'Name available!'
                                    ? 'bg-gray-400 cursor-not-allowed text-gray-700 border-gray-300'
                                    : 'text-white bg-indigo-600 hover:bg-indigo-700 mb focus:ring-indigo-500'
                                  }  ${getResponse === false && successful === false && nameAvailability === "Name available!"? "mb-24" : ""} `}
                                disabled={deploying || nameAvailability !== 'Name available!'}
                              >
                                {deploying ? 'Deploying...' : `Deploy`}
                              </button>
                              {!deploying && nameAvailability !== 'Name available!' &&
                              <p className='text-red-500 pt-2 pb-12'>Check if the name is available first!</p>
                              }
                            </div>
                            {getResponse &&
                              <div className=' flex justify-center'>
                                <div className='rounded py-4 mt-4 mb-24 px-12 flex justify-center items-center bg-yellow-100'>
                                  <LoaderIcon className='text-yellow-600 w-5 animate-spin h-auto mr-1' />
                                  <p className="text-sm font-semibold text-yellow-600">
                                    Deploying... Please wait.
                                  </p>
                                </div>
                              </div>
                            }
                            {successful && details &&
                              <div className='flex justify-center'>
                                <div className='rounded flex items-center py-4 px-12 mt-4 mb-24 bg-green-100'>
                                  <CheckIcon className='text-green-600 w-5 h-auto mr-1' />
                                  <p className='text-green-600'> Your web service has been successfully deployed </p>
                                </div>
                              </div>
                            }
                          </form>
                        </div>
                    </>
                  )}
                    {selectedType === 'cron' && (
                      <form onSubmit={handleSubmitCron}>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                          <dl className="sm:divide-y sm:divide-gray-200">
                            {[
                              { label: "Deployment Name", name: "webAppName", type: "text" },
                              { label: "Repository URL", name: "repoUrl", type: "text" },
                              { label: "Branch", name: "branch", type: "text" },
                              { label: "Runtime", name: "runtime", type: "select", options: ["NodeJS", "Django"] },
                              { label: "Startup Command", name: "startupCommand", type: "text" },
                              { label: "Port Number", name: "portNumber", type: "text" },
                              { label: "Location", name: "location", type: "select", options: ["east-us-1", "us-east-2", "us-west-1"] },
                              { label: "NodeJS version", name: "nodejsVersion", type: "select", options: ["14.x", "16.x", "18.x", "20.x", "22.x"] },
                            ].map((field, index) => (
                              <div key={index} className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                <dt className="text-sm font-medium text-gray-500">{field.label}</dt>
                                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                  <input
                                    type={field.type}
                                    name={field.name}
                                    value={cronFormData[field.name]}
                                    onChange={handleCronFormChange}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                  />
                                </dd>
                              </div>
                            ))}
                          </dl>
                          <div className="mt-6 px-4">
                            <h4 className="text-lg font-medium text-gray-900 mb-4">Cron Jobs</h4>
                            {cronJobs.map((job, index) => (
                              <div key={index} className="flex px-4 items-center space-x-4 mb-4">
                                <input
                                  type="text"
                                  value={job.endpoint}
                                  onChange={(e) => handleCronJobChange(index, 'endpoint', e.target.value)}
                                  placeholder="Endpoint"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <input
                                  type="text"
                                  value={job.schedule}
                                  onChange={(e) => handleCronJobChange(index, 'schedule', e.target.value)}
                                  placeholder="Cron Schedule"
                                  className="flex-1 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                />
                                <button
                                  type="button"
                                  onClick={() => removeCronJob(index)}
                                  className="inline-flex items-center p-2 border border-transparent rounded-full shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                  <FaTrash className="h-5 w-5" aria-hidden="true" />
                                </button>
                              </div>
                            ))}
                            <button
                              type="button"
                              onClick={addCronJob}
                              className="mt-2 inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                              <FaPlus className="mr-2" /> Add Cron Job
                            </button>
                          </div>
                        </div>
                        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                          <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            disabled={deploying}
                          >
                            {deploying ? 'Deploying...' : 'Deploy Cron Job'}
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              )}
            </>
          )}
          {activeSection === 'billing' && <Billing auth={auth} db={db} deleteSubscription={deleteSubscription} hasProfile={hasProfile} subscription={subscription} setSubscription={setSubscription} userId={userId} user={user} />}
          {activeSection === 'teams' && <Teams db={db} auth={auth} />}
          {activeSection === 'user' && <AccountSettings db={db} auth={auth} user={user} />}
        </main>
      </div>
    </div>
  );
};

export default Dashboard;


