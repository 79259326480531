import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { Flame, Cog, DollarSign, Users } from "lucide-react";
import { CiGlobe } from "react-icons/ci";
import axios from "axios"
import { collection, getDocs, query, where, getDoc, limit, orderBy, deleteDoc, doc } from 'firebase/firestore';

const Deployment = ({ db, user }) => {
    const [editMode, setEditMode] = useState(false);
    const { id } = useParams();
    const [logs, setLogs] = useState([]);
    const [deploymentData, setDeploymentData] = useState(null);
    const [showTooltip, setShowTooltip] = useState(false);

    const uid = user?.uid;

    const fetchLogs = async () => {
        try {
            const logsRef = collection(db, "logs");
            const logsQuery = query(
                logsRef,
                where("userId", "==", uid),
                orderBy("timestamp", "desc"),
                limit(20)
            );

            const logsSnapshot = await getDocs(logsQuery);
            const deploymentLogs = logsSnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data()
            }));

            console.log("Retrieved logs:", deploymentLogs);
            setLogs(deploymentLogs);
        } catch (error) {
            console.error("Error fetching logs:", error);
        }
    };

    useEffect(() => {
        const fetchDeployment = async () => {
            try {
                const deploymentDoc = await getDoc(doc(db, "deployments", id));
                if (deploymentDoc.exists()) {
                    const deployment = deploymentDoc.data();
                    setDeploymentData(deployment);
                    console.log("Deployment data retrieved:", deployment);

                    // Initial logs fetch
                    await fetchLogs();
                } else {
                    console.error("No such deployment!");
                }
            } catch (error) {
                console.error("Error fetching deployment:", error);
            }
        };

        fetchDeployment();

        const logsInterval = setInterval(fetchLogs, 1000);
        return () => {
            clearInterval(logsInterval);
        };
    }, [db]);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(`https://${deploymentData?.endpoint}`);
        setShowTooltip(true);
    };

    useEffect(() => {
        if (showTooltip) {
            const timer = setTimeout(() => setShowTooltip(false), 2000);
            return () => clearTimeout(timer);
        }
    }, [showTooltip]);


    const deleteDeployment = async (id) => {

        try {

            {/*
                      const subscriptionResponse = await fetch(`https://mia.my-coolcloud.com/cancel-subscription/${subsId}`, { method: 'DELETE' });
      if (!subscriptionResponse.ok) {
        throw new Error(`Failed to cancel subscription: ${subscriptionResponse.statusText}`);
      }
      

      const deleteInstanceResponse = await fetch(`https://mia.my-coolcloud.com/delete/${instanciaId}`, { method: 'DELETE' });
      if (!deleteInstanceResponse.ok) {
        throw new Error(`Failed to delete instance: ${deleteInstanceResponse.statusText}`);
      }
                */}

            const deploymentRef = doc(db, "deployments", id);
            await deleteDoc(deploymentRef);

            console.log("Deployment and subscription deleted successfully.");
            window.location.reload()
        } catch (error) {
            console.error("Error deleting deployment or subscription:", error);
            throw error;
        } finally {

        }
    };

    const [updatedData, setUpdatedData] = useState({
        repoUrl: '',
        webAppName: '',
        nodejsVersion: null,
        envVars: [],
        plan: 'Free',
        portNumber: null,
        runtime: '',
        location: 'amd',
        branch: null,
        deploymentId: '',
        startupCommand: ''
    })

    const handleInputChange = (e) => {
        setUpdatedData({
            ...updatedData,
            [e.target.name]: e.target.value
        });
    };
    
    const [activeSection, setActiveSection] = useState('main');

    const menuItems = [
        { icon: Flame, text: "main", onClick: () => setActiveSection('main') },
        { icon: DollarSign, text: "Deploys", onClick: () => setActiveSection('deploys') },
        { icon: Users, text: "Logs", onClick: () => setActiveSection('logs') },
    ];


    const handleSaveChanges = async (selectedDeployment) => {
        console.log(selectedDeployment)

        const modifiableData = {
            repoUrl: selectedDeployment?.data?.repoUrl,
            webAppName: selectedDeployment?.data?.webAppName,
            location: updatedData.location,
            plan: updatedData.plan,
            envVars: selectedDeployment?.data?.envVars,
            portNumber: updatedData.portNumber || selectedDeployment?.data?.portNumber,
            runtime: selectedDeployment?.data?.runtime,
            deploymentId: selectedDeployment?.data?.deploymentId,
            startupCommand: selectedDeployment?.data?.startupCommand,
            nodejsVersion: updatedData.nodejsVersion || selectedDeployment?.data?.nodejsVersion,
            branch: updatedData.branch || selectedDeployment?.data?.branch
        };

        try {
            setEditMode(false);
            const response = await axios.post(
                'http://localhost:5000/update-instance',
                {
                    data: modifiableData,
                    instanceId: selectedDeployment?.data?.instanceId,
                    uid: selectedDeployment?.data?.uid
                }
            );

        } catch (error) {
            console.error('Error saving changes:', error);
        }
    };

    return(
        <>
            <header className="flex items-center py-4 sm:px-4 justify-between">
                <p>Fino</p>
                <p>User</p>
            </header>
            <div className="flex flex-col h-screen md:flex-row">
            <nav className="mt-6">
                {menuItems.map((item, index) => (
                    <a
                        key={index}
                        onClick={item.onClick}
                        className="flex items-center px-6 py-3 text-gray-600 hover:bg-indigo-50 hover:text-indigo-600 cursor-pointer"
                    >
                        <item.icon className="mr-3" size={20} />
                        {item.text}
                    </a>
                ))}
            </nav>
            <div className="flex justify-center">
                <div className="w-5/6 sm:w-3/4">
                    <div className="bg-white flex-1 overflow-y-auto shadow overflow-hidden sm:rounded-lg">
                        <div className="px-4 pt-5 pb-3 sm:px-6 flex justify-between items-center">
                            <div className='flex items-center'>
                                <CiGlobe className='w-6 mr-2 h-auto text-gray-400' />
                                Web service
                            </div>
                            <div className='space-x-4'>
                                <button
                                    className="px-4 py-2 border border-2 border-green-500 hover:bg-green-100 text-sm font-medium rounded-md text-green-500"
                                >
                                    Deploy latest
                                </button>
                                <button
                                    onClick={() => setEditMode(!editMode)}
                                    className="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                                >
                                    {editMode ? 'Cancel' : 'Edit'}
                                </button>
                            </div>
                        </div>
                        <div className='px-6 pb-5'>
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {deploymentData?.data?.webAppName}
                            </h3>
                        </div>
                        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                            <dl className="sm:divide-y sm:divide-gray-200">
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Repository</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <a href={deploymentData?.data?.repoUrl} target="_blank" rel="noopener noreferrer" className="text-indigo-600 hover:text-indigo-500">
                                            {deploymentData?.data?.repoUrl}
                                        </a>
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Location</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {editMode ? (
                                            <select
                                                name="location"
                                                value={updatedData.location}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            >
                                                <option value="eastus">East US</option>
                                                <option value="westus">West US</option>
                                                <option value="centralus">Central US</option>
                                            </select>
                                        ) : (
                                            updatedData.location || deploymentData?.data?.location
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Plan</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {editMode ? (
                                            <select
                                                name="plan"
                                                value={updatedData.plan}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            >
                                                <option value="Free">Free</option>
                                                <option value="Starter">Starter</option>
                                                <option value="Standard">Standard</option>
                                                <option value="Premium">Premium</option>
                                                <option value="Pro">Pro</option>
                                                <option value="Plus">Plus</option>
                                            </select>
                                        ) : (
                                            updatedData.plan || deploymentData?.data?.plan
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Port number</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {editMode ? (
                                            <input
                                                name="portNumber"
                                                value={updatedData.portNumber}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            />
                                        ) : (
                                            updatedData.portNumber || deploymentData?.data?.portNumber
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">NodeJS version</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {editMode ? (
                                            <input
                                                name="nodejsVersion"
                                                value={updatedData.nodejsVersion}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            />
                                        ) : (
                                            updatedData.nodejsVersion || deploymentData?.data?.nodejsVersion
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Branch name</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        {editMode ? (
                                            <input
                                                name="branch"
                                                value={updatedData.branch}
                                                onChange={handleInputChange}
                                                className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                            />
                                        ) : (
                                            updatedData.branch || deploymentData?.data?.branch
                                        )}
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Endpoint</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 flex items-center">
                                        <a
                                            className="cursor-pointer text-indigo-500 hover:text-indigo-600 mr-2"
                                            target="_blank"
                                            href={`https://${deploymentData?.endpoint}`}
                                        >
                                            https://{deploymentData?.endpoint}
                                        </a>
                                        <div className="relative">
                                            <button
                                                onClick={copyToClipboard}
                                                className="text-gray-400 hover:text-gray-600 focus:outline-none"
                                                aria-label="Copy endpoint"
                                            >
                                                <CopyIcon />
                                            </button>
                                            {showTooltip && (
                                                <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded shadow-lg">
                                                    Copied!
                                                </div>
                                            )}
                                        </div>
                                    </dd>
                                </div>
                                <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                                    <dt className="text-sm font-medium text-gray-500">Delete web service</dt>
                                    <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                                        <button
                                            onClick={() => {
                                                const id = deploymentData?.id;
                                                const subscriptionId = deploymentData?.subscriptionId;
                                                deleteDeployment(id, subscriptionId);
                                            }}
                                            className={`inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500`}
                                        >
                                            Delete
                                        </button>
                                    </dd>
                                </div>
                            </dl>
                        </div>
                        {editMode && (
                            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                                <button
                                    onClick={() => handleSaveChanges(deploymentData)}
                                    className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Save Changes
                                </button>
                            </div>
                        )}
                        <div className='px-8 mb-32'>
                            <section className="mt-8">
                                <h2 className="text-xl font-semibold mb-4 text-white">Console</h2>
                                <div
                                    className="bg-black text-white p-4 rounded-lg shadow-inner overflow-y-auto"
                                    style={{ height: '400px', fontFamily: 'Courier New, Courier, monospace', fontSize: '14px' }}
                                >
                                    {logs.length === 0 ? (
                                        <p className="text-gray-400">No logs available.</p>
                                    ) : (
                                        <ul>
                                            {logs.map((log) => (
                                                <li key={log.id} className="mb-2">
                                                    <span className="text-green-500">{log.logName}</span>: {log.content}{' '}
                                                    <span className="text-gray-500 text-sm">
                                                        ({new Date(log.timestamp?.toDate()).toLocaleString()})
                                                    </span>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
</div>
</>
    )
}

export default Deployment;

const CopyIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect>
        <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path>
    </svg>
);