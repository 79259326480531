import React, {useState, useEffect} from "react"
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, deleteDoc, doc, updateDoc, arrayRemove, onSnapshot } from 'firebase/firestore';
import { Trash2, Pen } from 'lucide-react';

const Teams = ({ auth, db }) => {
    const [newEditor, setNewEditor] = useState({ name: '', email: '' });
    const [teamMembers, setTeamMembers] = useState([]);
    const [error, setError] = useState('');

    const fetchTeams = async () => {
        const user = auth.currentUser;
        if (user) {
            const q = query(collection(db, 'teams'), where('members', 'array-contains', user.uid));
            const querySnapshot = await getDocs(q);
            const members = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setTeamMembers(members);
        }
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const handleInputChange = (e) => {
        setNewEditor({ ...newEditor, [e.target.name]: e.target.value });
    };

    const handleAddEditor = async (e) => {
        e.preventDefault();
        setError('');

        if (!newEditor.name || !newEditor.email) {
            setError('Please fill in all fields.');
            return;
        }

        try {
            const user = auth.currentUser;
            await addDoc(collection(db, 'teams'), {
                ownerId: user.uid,
                members: [user.uid],
                name: newEditor.name,
                email: newEditor.email,
                role: 'Editor'
            });

            setNewEditor({ name: '', email: '' });
            fetchTeams();
        } catch (error) {
            setError('Failed to add editor. Please try again.');
            console.error('Error adding editor:', error);
        }
    };

    const handleRemoveEditor = async (editorId) => {
        try {
            const user = auth.currentUser;
            const teamDoc = doc(db, 'teams', editorId);
            await updateDoc(teamDoc, {
                members: arrayRemove(user.uid)
            });
            fetchTeams();
        } catch (error) {
            setError('Failed to remove editor. Please try again.');
            console.error('Error removing editor:', error);
        }
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Teams</h2>
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Your Team</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Manage your team members here.</p>
                </div>
                <div className="border-t border-gray-200">
                    <ul className="divide-y divide-gray-200">
                        {teamMembers.map((member) => (
                            <li key={member.id} className="px-4 py-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                    <div className="flex items-center">
                                        <div className="flex-shrink-0 h-10 w-10 rounded-full bg-gray-300"></div>
                                        <div className="ml-4">
                                            <div className="text-sm font-medium text-gray-900">{member.name}</div>
                                            <div className="text-sm text-gray-500">{member.email}</div>
                                        </div>
                                    </div>
                                    <div className="flex items-center">
                                        <div className="text-sm text-gray-500 mr-4">{member.role}</div>
                                        {member.ownerId !== auth.currentUser.uid && (
                                            <button
                                                onClick={() => handleRemoveEditor(member.id)}
                                                className="text-red-600 hover:text-red-800"
                                            >
                                                <Trash2 size={20} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="mt-8 bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">Add New Editor</h3>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">Invite a new team member as an editor.</p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <form onSubmit={handleAddEditor} className="space-y-4">
                        <div>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">Name</label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={newEditor.name}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                        <div>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">Email</label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={newEditor.email}
                                onChange={handleInputChange}
                                className="mt-1 focus:ring-indigo-500 p-2 border border-indigo-300 outline-none focus:border-indigo-500 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
                            />
                        </div>
                        {error && <p className="text-red-500 text-sm">{error}</p>}
                        <button
                            type="submit"
                            className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Add Editor
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Teams;