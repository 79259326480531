import React from 'react';

const Footer = () => {
    const footerLinks = {
        Product: ['Changelog', 'Blogs', 'Feedback', 'Pricing', 'Docs'],
        Company: ['About', 'Join Us'],
        Contact: ['Discord', 'Twitter', 'Email', 'GitHub'],
        Legal: ['Terms of Service', 'Privacy Policy']
    };

    return (
        <footer className="bg-[#0a051d] text-gray-400 py-12 px-6 relative overflow-hidden">
            {/* Gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-br from-transparent via-purple-900/20 to-purple-800/20 pointer-events-none" />

            <div className="max-w-6xl mx-auto relative z-10">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
                    {/* Logo Section */}
                    <div className="col-span-2 md:col-span-1 mb-8 md:mb-0">
                        <div className="flex items-center space-x-2 mb-4">
                            <img className='w-24 h-auto' src='https://maresone.s3.us-east-1.amazonaws.com/fino_logo.png' alt='' />
                        </div>
                    </div>

                    {/* Links Sections */}
                    {Object.entries(footerLinks).map(([category, links]) => (
                        <div key={category} className="flex flex-col">
                            <h3 className="text-white font-medium mb-4">{category}</h3>
                            <ul className="space-y-3">
                                {links.map((link) => (
                                    <li key={link}>
                                        <a
                                            href="#"
                                            className="hover:text-white transition-colors duration-200"
                                        >
                                            {link}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>

                {/* Copyright */}
                <div className="mt-12 pt-8 border-t border-gray-800">
                    <p className="text-sm text-gray-500">
                        © 2024 Fino Pte. Ltd.
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;