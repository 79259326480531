import React from 'react';
import { Cpu, MemoryStickIcon } from 'lucide-react';
import Header from "./header"
import Footer from './footer';

const Pricing = () => {

    const pricingData = [
        { tier: 'Starter', ram: '512MB', cpu: '0.5-1', renderPrice: '$7/month', coolCloudPrice: 'free', awsPrice: '$4.18/month' },
        { tier: 'Standard', ram: '2GB', cpu: '1', renderPrice: '$25/month', coolCloudPrice: '$12/month', awsPrice: '$16.56/month' },
        { tier: 'Pro', ram: '4GB', cpu: '2', renderPrice: '$85/month', coolCloudPrice: '$25/month', awsPrice: '$33.41/month' },
        { tier: 'Pro Plus', ram: '8GB', cpu: '4', renderPrice: '$175/month', coolCloudPrice: '$50/month', awsPrice: '$133.63/month' },
        { tier: 'Pro Max', ram: '16GB', cpu: '4', renderPrice: '$225/month', coolCloudPrice: '$99/month', awsPrice: '$133.63/month' },
        { tier: 'Pro Ultra', ram: '32GB', cpu: '8', renderPrice: '$450/month', coolCloudPrice: '$190/month', awsPrice: '$267.26/month' }
    ];

    return (
<div className='bg-black'>
<Header/>
            <div className="p-6 max-w-screen-lg pt-16 pb-32 mx-auto text-white rounded-lg">
                <h2 className="text-3xl font-bold mb-4 text-center">CCloud vs Render vs AWS</h2>
                <p className="mb-6 text-center text-lg">
                    CCloud offers the cheapest option while being easy to use
                </p>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {pricingData.map((row, index) => (
                        <div key={index} className="bg-white text-gray-800 rounded-lg shadow-lg p-6 hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-xl font-semibold mb-2">{row.tier}</h3>
                            <p className="flex items-center mb-2"><MemoryStickIcon className="inline-block mr-1" />{row.ram} RAM</p>
                            <p className="flex items-center mb-2"><Cpu className="inline-block mr-1" />{row.cpu} CPU</p>
                            <div className="mt-4">
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-800 font-semibold">CCloud: {row.coolCloudPrice} <a className="rounded p-1 border border-green-500 text-green-500 text-xs">Cheapest</a> </span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span className="text-gray-800 font-semibold">AWS: {row.awsPrice}</span>
                                </div>
                                <div className="flex justify-between mb-2">
                                    <span className="text-gary-800 font-semibold">Render: {row.renderPrice}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div> 
            <Footer/>
</div>
    );
};

export default Pricing;  