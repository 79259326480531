import React, {useState} from "react"
import {Link} from "react-router-dom"

const Header = ({user}) => {

    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

    return(
        <>
            <header className="w-full">
                <div className="container mx-auto flex items-center py-4 px-6">
                    <div className="flex w-1/3 flex justify-center items-center">
                        <Link to="/" className="text-2xl cursor-pointer font-bold text-purple-400">Fino</Link>
                    </div>
                    <div className="flex w-1/3 justify-evenly items-center space-x-4">
                        <a className="hidden md:flex space-x-8">
                            <Link to="/pricing" className="text-gray-300 flex text-sm items-center hover:text-purple-400 transition-colors">
                                Pricing
                            </Link>
                        </a>
                        <a href="mailto:hector.diaz@pucp.edu.pe" className="text-gray-300 text-sm sm:flex hidden items-center hover:text-purple-400 transition-colors">
                            Contact
                        </a>
                        <a target="_blank" href="https://youtu.be/49CWIrgQwjI" className="text-gray-300 text-sm sm:flex hidden items-center hover:text-purple-400 transition-colors">
                            Tutorial
                        </a>
                        {user && (
                            <Link to="/dashboard" className="text-gray-300 text-sm hidden sm:flex items-center hover:text-purple-400 transition-colors">
                                Dashboard
                            </Link>
                        )}
                    </div>
                    <div className="w-1/3 flex justify-center">
                        <button className="px-4 py-2 rounded-full text-white border-white border-2 hover:bg-gray-800">
                            Login
                        </button>
                    </div>
                    <button className="md:hidden text-gray-300" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
                        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
                        </svg>
                    </button>
                </div>
                {mobileMenuOpen && (
                    <nav className="md:hidden bg-[#0a051d]/95 border-b border-gray-800">
                        <div className="px-6 py-4 space-y-2">
                            <Link to="/pricing" className="block text-gray-300 hover:text-purple-400 transition-colors">
                                Pricing
                            </Link>
                            <a href="mailto:hector.diaz@pucp.edu.pe" className="block text-gray-300 hover:text-purple-400 transition-colors">
                                Contact
                            </a>
                            {user && (
                                <Link to="/dashboard" className="block text-gray-300 hover:text-purple-400 transition-colors">
                                    Dashboard
                                </Link>
                            )}
                        </div>
                    </nav>
                )}
            </header>
        </>
    )
}

export default Header;