import React, {useState, useEffect} from "react"
import { getFirestore, collection, getDocs, query, where, getDoc, setDoc, addDoc, serverTimestamp, deleteDoc, doc, updateDoc, arrayRemove, onSnapshot } from 'firebase/firestore';
import { Star, Flame, Cog, DollarSign, Users, ArrowLeft, Plus, Menu, User, CheckCheck, CircleX, CheckIcon, LoaderIcon, Globe2, ArrowRightIcon, CheckCircleIcon } from "lucide-react";

const Bplans = [
    { plan: "Beta", price: "6", cpu: "1", ram: "1", variantId: "531421" },
    { plan: "Omega", price: "12", cpu: "1", ram: "2", variantId: "531422" },
    { plan: "Delta", price: "18", cpu: "2", ram: "2", variantId: "531423" }
];

const Billing = ({ user, subscription, deleteSubscription, hasProfile, userId, auth, db }) => {
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubscribe = async () => {
        if (!selectedPlan) {
            setError('Please select a plan before subscribing.');
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const response = await fetch('https://mia.my-coolcloud.com/checkout', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    variantId: selectedPlan.variantId,
                    userId: user.uid,
                    userEmail: user.email,
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to create checkout link');
            }

            const responseData = await response.json();
            const { checkoutUrl } = responseData;

            const docData = {
                uid: user.uid,
                response: {
                    plan: selectedPlan.plan,
                    cpu: selectedPlan.cpu,
                    ram: selectedPlan.ram,
                    variantId: selectedPlan.variantId,
                    status: response.status,
                    ok: response.ok,
                    url: response.url,
                },
                checkoutUrl: checkoutUrl,
                createdAt: new Date().toISOString()
            };

            const userRef = doc(db, 'payments_profile', userId);
            await setDoc(userRef, docData);

            window.location.href = checkoutUrl;

        } catch (error) {
            console.error('Error creating checkout link:', error);
            setError('Failed to initiate checkout. Please try again.');
        } finally {
            setIsLoading(false);
        }
    };

    const formatDate = (dateString) => {
        return new Date(dateString).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
    };

    return (
        <div className="p-6">
            <h2 className="text-2xl font-semibold mb-4">Billing</h2>

            {(subscription && hasProfile) ? (
                <div className="bg-white w-full sm:w-1/2 shadow-lg rounded-lg overflow-hidden">
                    <div className="px-6 py-8">
                        <h3 className=" font-semibold mb-6">Your Current Subscription</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <p className="text-gray-600 mb-2">Status</p>
                                <div className='flex text-sm items-center space-x-3'>
                                    <p className="text-sm">{subscription.attributes.status_formatted}</p>
                                    <CheckCircleIcon className='w-4 h-auto text-green-600' />
                                </div>
                            </div>
                            <div>
                                <p className="text-gray-600 text-sm mb-2">Plan</p>
                                <p className="text-sm">{subscription.attributes.user_name}</p>
                            </div>
                            <div>
                                <p className="text-gray-600 mb-2">Invoice Date</p>
                                <p className="text-sm">{formatDate(subscription.attributes.created_at)}</p>
                            </div>
                            <div>
                                <p className="text-gray-600 text-sm mb-2">Amount</p>
                                <p className="text-sm">{subscription.attributes.total_formatted} USD</p>
                            </div>
                            <div>
                                <p className="text-gray-600 text-sm mb-2">Payment Method</p>
                                <p className="text-sm">
                                    {subscription.attributes.card_brand.toUpperCase()} ending in {subscription.attributes.card_last_four}
                                </p>
                            </div>
                            <div>
                                <p className="text-gray-600 text-sm mb-2">Cancel Subscription</p>
                                <button onClick={deleteSubscription} className='px-5 py-2 rounded text-sm text-white bg-red-500 hover:bg-red-600'>Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="mt-8">
                    <h3 className="text-2xl font-semibold mb-6">Select a Plan</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {Bplans.map((plan) => (
                            <div
                                key={plan.plan}
                                className={`border p-6 rounded-lg cursor-pointer transition duration-300 ${selectedPlan?.plan === plan.plan
                                    ? 'border-indigo-500 bg-blue-50'
                                    : 'border-gray-200 hover:border-indigo-300 hover:bg-gray-50'
                                    }`}
                                onClick={() => setSelectedPlan(plan)}
                            >
                                <div className='flex justify-between'>
                                    <div>
                                        <h4 className="font-medium mb-2">{plan.plan}</h4>
                                        <p className="text- mb-4">${plan.price}<span className="text-sm font-normal">/month</span></p>
                                    </div>
                                    <div>
                                        <p>CPU: {plan.cpu} core(s)</p>
                                        <p>RAM: {plan.ram} GB</p>
                                    </div>
                                </div>
                                <ul className="space-y-2">
                                </ul>
                            </div>
                        ))}
                    </div>
                    <button
                        onClick={handleSubscribe}
                        disabled={isLoading || !selectedPlan}
                        className="mt-8 bg-indigo-500 text-white px-6 font-semibold py-2 rounded-md hover:bg-indigo-600 disabled:opacity-50 disabled:cursor-not-allowed transition duration-300"
                    >
                        {isLoading ? 'Processing...' : 'Subscribe Now'}
                    </button>
                </div>
            )}

            {error && (
                <div className="mt-6 text-red-600 bg-red-100 border border-red-400 rounded-md p-4">
                    {error}
                </div>
            )}
        </div>
    );
};

export default Billing;